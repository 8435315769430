import React from 'react'
import Layout from "../components/Layout"
import Impressum from "../components/Impressum"
import {SEO} from "../components/Seo";

const imprint = () => {
    return (
        <Layout>
            <Impressum/>
        </Layout>
    )
}

export default imprint

export const Head = () => (
    <SEO title="Imprint | Globale Trade Services | SAP-Knowledge like an espresso (books & e-books)" />
)
