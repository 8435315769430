import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import BookItem from "./blog/BookItem";

const Impressum = () => {
    const books = useStaticQuery(graphql`
    {
        allMarkdownRemark {
        nodes {
            frontmatter {
                title
                tag
                slug
                excerpt
                link
                price
                pages
                claim
                features
                language
                image {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
            html
            }
        }
    }
    `)

    const allBooks = books.allMarkdownRemark.nodes.map((item, index) => (
        <BookItem
            key={index}
            slug={item.frontmatter.slug}
            alt={item.frontmatter.title}
            title={item.frontmatter.title}
            tag={item.frontmatter.tag}
            link={item.frontmatter.link}
            excerpt={item.frontmatter.excerpt}
            image={item.frontmatter.image}
            price={item.frontmatter.price}
            claim={item.frontmatter.claim}
            features={item.frontmatter.features}
            pages={item.frontmatter.pages}
            language={item.frontmatter.language}
        />
    ))

    return (
        <div>
            <section className="bg-blue-200 text-gray-800 pt-16 pb-10 text-left">
                <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
                    <h1 className="font-semibold md:text-4xl text-2xl pb-4">Imprint</h1>
                    <p className="lg:text-2xl sm:text-lg text-sm">Espresso Tutorials GmbH</p>
                </div>
            </section>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap py-10">
                <div className="w-full md:w-content md:pr-16 lg:pr-24">
                    <div className="w-full mt-9 article">
                        <div className="prose lg:prose-xl">
                            <p>Espresso Tutorials GmbH<br/>
                                Bahnhofstrasse 2<br/>
                                37130 Gleichen</p>
                            <p>Phone +49 551 790 894 80<br/>
                                Fax +49 551 790 894 81</p>
                            <p>E-Mail: <a href="mailto:info@espresso-tutorials.com">info@espresso-tutorials.com</a><br/>
                                Founders Martin Munzel and Joerg Siebert</p>
                            <p>Flags used in our Online Library by <a href="https://espresso-tutorials.com/www.icondrawer.com">icondrawer.com </a></p>
                        </div>
                    </div>
                </div>
                <div className="w-full w-sidebar mt-12 md:mt-0">
                    <h2 className="font-bold text-gray-800 text-2xl">Our books</h2>
                    <div className="mt-8">
                        {allBooks}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Impressum
