import React from "react"
import { useSiteMetadata } from "../hooks/use-site-meta"
import { useLocation } from '@reach/router';

export const SEO = ({ title, description, pathname, children }) => {
    const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername: twitterUsername,
    }

    const location = useLocation();
    // construct the meta array for passing into react helmet.
    const metas = [
        // basic seo
        {
            name: 'description',
            content: description,
        },
        {
            name: 'og:image',
            content: seo.image,
        },
        {
            name: 'og:image:width',
            content: `1200`,
        },
        {
            name: 'og:image:height',
            content: `630`,
        },
        {
            name: 'og:type',
            content: 'website',
        },
        {
            name: 'og:title',
            content: title,
        },
        {
            name: 'og:description',
            content: description,
        },
        {
            name: 'og:site_name',
            content: title,
        },
        {
            name: 'og:url',
            content: `${siteUrl}${location.pathname}`,
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image',
        },
        {
            name: 'twitter:description',
            content: description,
        },
        {
            name: 'twitter:title',
            content: title,
        },
        {
            name: 'twitter:image',
            content: seo.image,
        },
        {
            name: 'twitter:creator',
            content: twitterUsername,
        },
    ];

    return (
        <>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {metas.map(meta => (
                <meta key={meta.name} name={meta.name} content={meta.content} />
            ))}
            {children}
        </>
    )
}
